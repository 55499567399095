import type { RouteLocationNormalizedGeneric } from 'vue-router';
const { isClientAuthenticated } = useAuthClientStorage();

export default defineNuxtRouteMiddleware(
   async (
      to: RouteLocationNormalizedGeneric,
      from: RouteLocationNormalizedGeneric
   ) => {
      if (isClientAuthenticated?.value) {
         if (to.name == 'auth-client-login') {
            if (from.name && to.name !== from.name) {
               return;
            } else {
               return navigateTo({ name: 'client-booking' });
            }
         }
         return;
      } else if (to.meta.requiresAuth && !isClientAuthenticated?.value) {
         if (to.name == 'auth-client-login') {
            return;
         }
         return navigateTo({ name: 'auth-client-login' });
      } else if (!isClientAuthenticated?.value) {
         if (to.name == 'auth-client-login') return;
         return navigateTo({ name: 'auth-client-login' });
      }
      return;
   }
);
